import React from 'react';
import { navigate } from '@reach/router';
import { isLoggedIn, getCurrentUser } from 'src/utils/auth';

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props;
    if (!isLoggedIn()) {
      navigate(`/app/login`);
      return null;
    }
    const user = getCurrentUser();
    return <Component {...rest} user={user} />;
  }
}

export default PrivateRoute;
