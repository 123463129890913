import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import InputMask from 'react-input-mask';
import Input from 'common/src/components/Input';
import Button from 'common/src/components/Button';
import { isRut } from 'common/src/utils/validator';
import { windowRedirect } from 'common/src/utils/helpers';
import { setUser } from 'src/utils/auth';
import { sendWelcomeEmail } from 'src/utils/sendMail';

const Warning = styled.div`
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: #7d7c7c;
  text-transform: uppercase;
  text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

const LoginFormWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 95vh;
  background: ${({ theme }) => theme.colors.background};

  .app-login {
    z-index: 1;
    width: 60vw;
    max-width: 500px;
    padding: 2.5rem 3rem;
    background: white;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    @media (max-width: 900px) {
      width: 65vw;
      padding: 2rem 1.5rem;
    }
    @media (max-width: 574px) {
      width: 80vw;
      padding: 2rem 1.5rem;
    }
    .login-title {
      padding: 0rem 1rem 1.5rem;
      font-size: 0.85rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.black};
      text-transform: uppercase;
      text-align: center;
    }
    form {
      label {
        font-size: 0.65rem;
        font-weight: 500;
        color: #7d7c7c;
        text-transform: uppercase;
        min-width: 100px;
        padding: 0rem 1.5rem;
      }
      .field-wrapper {
        padding: 0.5rem 1rem;
        .app-input {
          width: 100%;
        }
        input {
          font-size: 0.85rem;
          color: #666;
          border: none;
          background-color: #f1f1f1;
          padding: 1rem 1.5rem;
          border-radius: 2px;
        }
        button {
          padding: 1rem 1.5rem;
          border-radius: 2px;
          text-transform: uppercase;
          font-weight: 600;
          width: 100%;
          font-size: 0.85rem;
          background-color: ${({ theme }) => theme.colors.orange};
          color: ${({ theme }) => theme.colors.white};
        }
        &.error {
          text-align: center;
          background-color: #f8d7da;
        }
      }
    }
    .login-form-switch {
      text-align: center;
      color: #7d7c7c;
      font-size: 0.8rem;
      padding: 0.5rem;
    }
  }
`;
const RutInputWrapper = styled.div`
  ${({ valid, theme }) =>
    valid &&
    css`
      input {
        border: 1px solid ${theme.colors.success} !important;
      }
    `};
  ${({ error, theme }) =>
    error &&
    css`
      input {
        border: 1px solid ${theme.colors.danger} !important;
      }
    `};
`;

const Form = ({ onComplete }) => {
  const [loading, setLoading] = useState(false);
  const [rut, setRut] = useState('');
  const [validRut, setValidRut] = useState(null);
  const [email, setEmail] = useState('');
  // const [name, setName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('submitting');
    await setLoading(true);
    await setError('');
    if (password !== passwordConfirm) {
      await setLoading(false);
      return setError('*Contraseñas deben ser iguales');
    }
    if (!email || !rut || !password) {
      await setLoading(false);
      return setError('*Por favor, ingrese los datos requeridos');
    }
    // console.log({ rut, email, password });
    const { valid, parsedRut, message } = isRut(rut);
    // console.log({ valid, parsedRut, message });
    await setValidRut(valid);
    if (!valid) {
      await setLoading(false);
      return setError(message);
    }

    try {
      await Auth.signUp({
        username: parsedRut,
        password,
        attributes: {
          email,
          name: parsedRut
        }
      });
      // console.log({ user });
      // console.log('Sign up successful, signing in');
      await Auth.signIn(parsedRut, password);
      const signedUser = await Auth.currentAuthenticatedUser();
      await setUser(signedUser);
      await sendWelcomeEmail({ email: email });
      await setLoading(false);
      windowRedirect('/app');
    } catch (err) {
      // console.log(err.message);
      console.log({ err: err.message });
      let errMessage = err.message;
      await setError(err.message);
      if (
        err.message ===
        `1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6`
      ) {
        errMessage = `Contraseña debe ser tener mínimo 6 carácteres`;
      }
      if (err.message === `User already exists`) {
        errMessage = `Rut ya se encuentra registrado`;
      }

      await setError(errMessage);
      await setLoading(false);
    }
  };
  return (
    <LoginFormWrapper>
      <div className="app-login">
        <div className="login-title">Registro a plataforma</div>
        <form onSubmit={onSubmit}>
          <label htmlFor="rutid">Rut*</label>
          {/* <Input
            inputType="text"
            name="rut"
            placeholder="Ingresa tu RUT"
            masking="99.999.999-9"
            numericInput // mask direction right
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu Rut"
            onChange={(e) => setRut(e.target.value)}
            disabled={loading}
            value={rut}
          /> */}
          <RutInputWrapper valid={validRut} error={validRut === false}>
            <div className="field-wrapper">
              <InputMask
                type="text"
                name="rutid"
                placeholder="Ingresa tu número de Rut"
                mask="99.999.999-*"
                className="app-input"
                aria-label="Ingresa tu Rut"
                onChange={(e) => setRut(e.target.value)}
                disabled={loading}
                value={rut}
                onBlur={(e) => {
                  const noBlacks = e.target.value.replace(/_/g, '');
                  const noDots = noBlacks.replace(/\./g, '');
                  const noDash = noDots.replace(/-/g, '');
                  // console.log({ values: noDash, lenght: noDash.length });
                  // console.log(e.target.value);
                  if (noDash.length === 8) {
                    // meaning, finish with 1 char empty. Add empty to first value
                    setRut(`0${e.target.value}`);
                  }
                }}
              />
            </div>
          </RutInputWrapper>
          <label htmlFor="email">Email*</label>
          <Input
            inputType="email"
            name="email"
            placeholder="Ingresa tu dirección de correo"
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            value={email}
          />
          {/* <label htmlFor="name">Nombre*</label>
          <Input
            inputType="text"
            name="name"
            placeholder="Ingresa tu Nombre"
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu nombre"
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
            value={name}
          /> */}

          <label htmlFor="password">Contraseña*</label>
          <Input
            inputType="password"
            name="password"
            // passwordShowHide
            placeholder="Ingresa tu Contraseña"
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu Contraseña"
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            value={password}
          />
          <label htmlFor="passwordConfirm">Confirmar Contraseña*</label>
          <Input
            inputType="password"
            name="passwordConfirm"
            // passwordShowHide
            placeholder="Ingresa tu Contraseña nuevamente"
            iconPosition="right"
            isMaterial={false}
            className="app-input"
            aria-label="Ingresa tu Contraseña nuevamente"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            disabled={loading}
            value={passwordConfirm}
          />
          <div className="field-wrapper">
            <Button
              type="submit"
              title={loading ? 'Enviando...' : 'Ingresar'}
              disabled={loading}
              colors={loading ? 'disabledWithBg' : 'primaryWithBg'}
            />
          </div>
          {error && (
            <div className="field-wrapper error">
              <Warning>{error}</Warning>
            </div>
          )}
        </form>
        {/* <br /> */}
        <div className="login-form-switch">
          Ya estás registrado? <Link to="/app/login">Ingresa aquí</Link>
        </div>
        {/* <br /> */}
        <Warning>*Requerido</Warning>
        <Warning>
          Para ingresar debes ser <u>mayor de 18 años</u>. Si eres menor de 18
          años y necesitas ayuda haz click en{' '}
          <em>S.O.S. Psicológico En Línea</em>
        </Warning>
      </div>
    </LoginFormWrapper>
  );
};

export default Form;
