import React from 'react';
import Container from 'common/src/components/UI/ContainerTwo';
import LoginWrapper, { ContentWrapper } from './banner.style';
import Form from './Form';

const LoginForm = () => {
  return (
    <LoginWrapper>
      <Container>
        <ContentWrapper>
          <Form />
        </ContentWrapper>
      </Container>
    </LoginWrapper>
  );
};

export default LoginForm;
