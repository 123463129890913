import React from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../containers/BHP/LoginNavbar';
import ForgotPasswordForm from '../../containers/BHP/ForgotPasswordForm';
import { BHPWrapper, ContentWrapper } from '../../containers/BHP/bhp.style';
import { windowRedirect } from 'common/src/utils/helpers';
import { getCurrentUser } from 'src/utils/auth';

const SignUp = () => {
  const user = getCurrentUser();
  if (user && user.sub) {
    windowRedirect('/app');
  }

  return (
    <BHPWrapper>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
      <ContentWrapper>
        <ForgotPasswordForm />
      </ContentWrapper>
    </BHPWrapper>
  );
};

export default SignUp;
