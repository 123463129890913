import React, { Fragment } from 'react';
import { Router, Redirect } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import Loadable from '@loadable/component';
import { bhpTheme } from 'common/src/theme/bhp';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../components/seo';
import { GlobalStyle } from '../containers/BHP/bhp.style';

// import Layout from '../components/layout';
// import Details from '../components/App/Details';

// import Home from '../components/App/Home';
import Login from '../components/App/Login';
import SignUp from '../components/App/SignUp';
import ForgotPassword from '../components/App/ForgotPassword';
import PrivateRoute from '../components/App/PrivateRoute';
import { StageProvider } from '../components/App/Context';

const Home = Loadable(() => import('../components/App/Home'));
const AdminPage = Loadable(() => import('../components/App/Admin'));
const UserDetailsPage = Loadable(() => import('../components/App/UserDetails'));
// const DemographicsPage = Loadable(() =>
//   import('../components/App/DemographicsPage')
// );
const DASS21Page = Loadable(() => import('../components/App/DASS21Page'));
const IntroPage = Loadable(() => import('../components/App/IntroPage'));
const ParentSelectPage = Loadable(() =>
  import('../components/App/ParentSelectPage')
);
const ScreenChildPage = Loadable(() =>
  import('../components/App/ScreenChildPage')
);
const ScreenParentPage = Loadable(() =>
  import('../components/App/ScreenParentPage')
);
const PostChildPage = Loadable(() => import('../components/App/PostChildPage'));
const PostAdultPage = Loadable(() => import('../components/App/PostAdultPage'));

const TALLERPage = Loadable(() => import('../components/App/TallerPage'));

const App = () => {
  if (typeof window === 'undefined') {
    return <Redirect to="/" />;
  }
  return (
    // <Layout>
    <StageProvider>
      <ThemeProvider theme={bhpTheme}>
        <Fragment>
          <SEO title="Aprendiendo y Cuidando[Nos] en Comunidad" />
          <ResetCSS />
          <GlobalStyle />
          <Router>
            {/* <PrivateRoute path="/app/" component={Home} />
        <PrivateRoute path="/app/profile" component={Details} /> */}
            <PrivateRoute path="/app/" component={Home} />
            <PrivateRoute path="/app/intro" component={IntroPage} />
            <PrivateRoute path="/app/dass21" component={DASS21Page} />
            <PrivateRoute
              path="/app/parent-select"
              component={ParentSelectPage}
            />
            <PrivateRoute path="/app/parent" component={ScreenParentPage} />
            <PrivateRoute path="/app/child" component={ScreenChildPage} />
            <PrivateRoute path="/app/taller" component={TALLERPage} />

            {/* <TALLERPage path="/app/taller" /> */}
            {/* <IntroPage path="/app/intro" /> */}
            {/* <DASS21Page path="/app/dass21" /> */}
            {/* <ParentSelectPage path="/app/parent-select" /> */}
            {/* <ScreenParentPage path="/app/parent" /> */}
            {/* <ScreenChildPage path="/app/child" /> */}
            {/* <AdminPage path="/app/admin" /> */}
            <PrivateRoute path="/app/admin" component={AdminPage} />
            <PrivateRoute path="/app/u/:userID" component={UserDetailsPage} />
            {/* Post adult test */}

            <PrivateRoute
              path="/app/q/adult/:userID/:tallerSKU/:tallerName"
              component={PostAdultPage}
            />

            {/* Post child test */}
            <PrivateRoute
              path="/app/q/child/:userID/:tallerSKU/:tallerName"
              component={PostChildPage}
            />
            <Login path="/app/login" />
            <SignUp path="/app/signup" />
            <ForgotPassword path="/app/recuperar-password" />
          </Router>
        </Fragment>
      </ThemeProvider>
    </StageProvider>
    // </Layout>
  );
};

export default App;
