import axios from 'axios';

export function sendWelcomeEmail({ email }) {
  const POST_API =
    'https://usdewdowl3.execute-api.us-west-2.amazonaws.com/prod/mailer';
  const config = {
    email,
    from: 'uaimetrics@aprendiendoycuidandonos.cl',
    fromName: 'Aprendiendo y Cuidándonos - UAI Metrics',
    subject:
      'Bienvenido a Aprendiendo y Cuidando(Nos) en Comunidad – Red Educación Digital',
    template: 'red-edu-welcome'
  };
  return axios.post(`${POST_API}`, { ...config });
}

export function sendFinishUserLinkEmail({
  email,
  userID,
  emailType = 'adult',
  tallerSKU = 'TALLER01',
  tallerName = ''
}) {
  const POST_API =
    'https://usdewdowl3.execute-api.us-west-2.amazonaws.com/prod/mailer';

  // target url is either:
  // /app/q/adult/:userID/:tallerSKU/:tallerName
  // /app/q/child/:userID/:tallerSKU/:tallerName
  const tallerNameSafe = tallerName.trim().replace(/\ /g, '+'); // Nombre de Taller -> Nombre+de+Taller
  const tallerSKUSafe = tallerSKU.replace('TALLER', '');
  const userLink = `https://www.aprendiendoycuidandonos.cl/app/q/${emailType}/${userID}/${tallerSKUSafe}/${tallerNameSafe}`;
  // examples:
  // "https://www.aprendiendoycuidandonos.cl/app/q/adult/9a2217b7-69d1-4469-8739-6ae1d5d7384f/01/Taller+Prueba+2"
  // "https://www.aprendiendoycuidandonos.cl/app/q/child/9a2217b7-69d1-4469-8739-6ae1d5d7384f/01/Taller+Prueba+2"

  const config = {
    email,
    from: 'uaimetrics@aprendiendoycuidandonos.cl',
    fromName: 'Aprendiendo y Cuidándonos - UAI Metrics',
    subject: `Encuesta Evaluación final taller ${tallerName} - Aprendiendo y Cuidándonos`,
    template: 'red-edu-finish-link',
    variables: { userLink }
  };
  // console.log({ config });
  return axios.post(`${POST_API}`, { ...config });
  // return Promise.resolve();
}
