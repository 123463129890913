import React, { useState } from 'react';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { androidMenu } from 'react-icons-kit/ionicons/androidMenu';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import Logo from 'common/src/components/UIElements/Logo';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/ContainerTwo';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
// import LogoImage from 'common/src/assets/image/agencyModern/logo.png';
import LogoImage from './img/logo_psicologia.jpg';

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <NavbarWrapper className="agencyModern-navbar navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Aprendiendo y Cuidando[Nos] en Comunidad"
          className="main-logo"
        />
        {/* end of logo */}

        <MenuArea>
          <ul className="menu-items menu-left">
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/app/login">Login</Link>
            </li>
            <li>
              <Link to="/app/signup">Signup</Link>
            </li>
          </ul>
          {/* <ul className="menu-items menu-right">
            <li>
              <Link to="/">
                <Button title="Ingresar" type="button" />
              </Link>
            </li>
          </ul> */}
          {/* end of main menu */}

          <Button
            className="menubar"
            icon={
              mobileMenu ? (
                <Icon
                  style={{ color: '#02073E' }}
                  className="bar"
                  size={32}
                  icon={androidClose}
                />
              ) : (
                <Fade>
                  <Icon
                    style={{ color: '#02073E' }}
                    className="close"
                    icon={androidMenu}
                    size={32}
                  />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={handleMobileMenu}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${mobileMenu ? 'active' : ''}`}>
        <Container>
          <ul className="menu">
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/app/login">Login</Link>
            </li>
            <li>
              <Link to="/app/signup">Signup</Link>
            </li>
          </ul>
          {/* <Link to="/">
            <Button title="Ingresar" type="button" />
          </Link> */}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
