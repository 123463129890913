import React from 'react';
import Container from 'common/src/components/UI/ContainerTwo';
import SignupWrapper, { ContentWrapper } from './banner.style';
import Form from './Form';

const SignupForm = () => {
  return (
    <SignupWrapper>
      <Container>
        <ContentWrapper>
          <Form />
        </ContentWrapper>
      </Container>
    </SignupWrapper>
  );
};

export default SignupForm;
