const insertAt = (str, sub, pos) =>
  `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

const isRut = (rutStr) => {
  let rut = rutStr;
  if (!rutStr.includes('-')) {
    rut = insertAt(rutStr, '-', 12);
  }
  // console.log({ rut });
  const noUnderscore = rut.replace(/_/g, ''); // replace all underscores
  const noDots = noUnderscore.replace(/\./g, ''); // replace all dots

  const [base, digit] = noDots.split('-'); // split numbers and verificador digit
  let body = base;
  let dv = digit;
  if (!digit) {
    // meaning is the last of body
    const revDv = base.slice(-1);
    let revBase = base.slice(0, -1);
    // console.log({ nn, bb, body });
    if (revBase.charAt(0) === '0') {
      // console.log('first is 0');
      revBase = revBase.slice(1);
    }
    body = revBase;
    dv = revDv;
  }
  const verificador = dv.toLowerCase();
  if (body.length < 7) {
    // min characters for x.xxx.xxx
    return { valid: false, message: 'RUT Incompleto' };
  }
  // console.log({ body, verificador });

  function calculateDigitoVerificador(cuerpo) {
    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;
    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {
      // Obtener su Producto con el Múltiplo Correspondiente
      const index = multiplo * cuerpo.charAt(cuerpo.length - i);
      // Sumar al Contador General
      suma += index;
      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo += 1;
      } else {
        multiplo = 2;
      }
    }
    // Calcular Dígito Verificador en base al Módulo 11
    const dvEsperado = 11 - (suma % 11);
    if (dvEsperado === 10) return 'k';
    if (dvEsperado === 11) return '0';
    return `${dvEsperado}`;
  }

  const dvCalc = calculateDigitoVerificador(body);
  const isVerificadorEqual = dvCalc === verificador;
  if (!isVerificadorEqual) {
    return { valid: false, message: 'RUT no es válido' };
  }
  return {
    valid: isVerificadorEqual,
    parsedRut: `${body}-${dv}`,
    message: 'Rut válido'
  };
};

export { isRut };
export default isRut;
