import { navigate } from '@reach/router';

export const windowRedirect = (path) => {
  return window ? (window.location.href = path) : navigate(path);
};

// parse url query string, e.g.
// url test mode ?mode=testingMode
export const getUrlQuery = (param) => {
  if (!param) {
    return null;
  }
  const query = new URLSearchParams(window.location.search);
  const result = query.get(param);
  return result;
};
